// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//API URLs
import { Documentum } from "./urlFactory/documentum";
import { GbAuthorization } from "./urlFactory/gb-authorization";
import { GbBankvalidation } from "./urlFactory/gb-bankvalidation";
import { GbBlazebom } from "./urlFactory/gb-blazebom";
import { GbBlazeRules } from "./urlFactory/gb-blazerules";
import { GbCase } from "./urlFactory/gb-case";
import { GbCiamCommonUtil } from "./urlFactory/gb-ciam-common-util";
import { GbCiamOrch } from "./urlFactory/gb-ciam-orch";
import { GbClaims } from "./urlFactory/gb-claims";
import { GbContent } from "./urlFactory/gb-content";
import { GbCxrEmail } from "./urlFactory/gb-cxr-email";
import { GbLeaves } from "./urlFactory/gb-cxr-leave";
import { GbCxrRules } from "./urlFactory/gb-cxr-rules";
import { GbEconsent } from "./urlFactory/gb-econsent";
import { GbIntake } from "./urlFactory/gb-intake";
import { GbIntakeQuestions } from "./urlFactory/gb-intake-questions";
import { GbLog } from "./urlFactory/gb-log";
import { GbLogin } from "./urlFactory/gb-login";
import { GbMudi } from "./urlFactory/gb-mudi";
import { OrchService } from "./urlFactory/gb-orchService";
import { GbPha } from "./urlFactory/gb-pha";
import { GbPolicy } from "./urlFactory/gb-policy";
import { GbProfileSync } from "./urlFactory/gb-profile-sync";
import { GbSsoRegistration } from "./urlFactory/gb-sso-registration";
import { GbTealium } from "./urlFactory/gb-tealium";
import { THAWDBService } from "./urlFactory/gb-thawdb";
import { GbTrillium } from "./urlFactory/gb-trillium";
import { THAAWKABPortalService } from "./urlFactory/thaa-WKABPortalService";

export class Environment {
  constructor(
    private clientId: Map<string, string>,
    private baseUrl: string,
    private cxrBase: string,
    private thaaBase: string,
    private envName: string,
    private mockingSet: boolean,
    private prodState: boolean,
    private proxyState: boolean,
    private ciamAccountUrl: string,
    private sfdAccountUrl: string,
    private hitQA: boolean,
    private psvCdnUrl: string,
    private psvGoogleAnalyticKey: string,
    private recaptchaKey: string,
    private ciamApiSgwUrl: string,
  ) {}

  getEnv() {
    return {
      //Common Application parameters
      production: this.prodState,
      mocking: this.mockingSet,
      envName: this.envName,
      myBaseUrl: this.baseUrl,
      myClientId: this.clientId,
      cxrBase: this.cxrBase,
      thaaBase: this.thaaBase,
      proxyState: this.proxyState,
      ciamAccountUrl: this.ciamAccountUrl,
      sfdAccountUrl: this.sfdAccountUrl,
      hitqa: this.hitQA,
      psvCdnUrl: this.psvCdnUrl,
      psvGoogleAnalyticKey: this.psvGoogleAnalyticKey,
      recaptchaKey: this.recaptchaKey,
      //New way to incorporate the services
      gbClaims: new GbClaims(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbMudi: new GbMudi(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbAuthorization: new GbAuthorization(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbBankValidation: new GbBankvalidation(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbBlazebom: new GbBlazebom(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbCiamCommonUtil: new GbCiamCommonUtil(
        this.clientId,
        this.baseUrl,
        this.envName,
        this.ciamAccountUrl,
        this.sfdAccountUrl,
        this.ciamApiSgwUrl,
      ).getUrls(),
      gbCiamOrch: new GbCiamOrch(
        this.clientId,
        this.baseUrl,
        this.envName,
        this.ciamAccountUrl,
        this.sfdAccountUrl,
        this.ciamApiSgwUrl,
      ).getUrls(),
      gbContent: new GbContent(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbCxrEmails: new GbCxrEmail(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbEconsent: new GbEconsent(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbLog: new GbLog(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbLogin: new GbLogin(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbPha: new GbPha(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbPolicy: new GbPolicy(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbTrillium: new GbTrillium(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbCxrRules: new GbCxrRules(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbSsoRegistration: new GbSsoRegistration(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbBlazeRules: new GbBlazeRules(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbCase: new GbCase(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbTealium: new GbTealium(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbProfileSync: new GbProfileSync(this.clientId, this.baseUrl, this.envName).getUrls(),
      documentum: new Documentum(this.clientId, this.baseUrl, this.envName).getUrls(),
      thaaWKABPortalService: new THAAWKABPortalService(
        this.clientId,
        this.baseUrl,
        this.envName,
      ).getUrls(),
      gbLeaves: new GbLeaves(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbORCHService: new OrchService(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbTHAWDBService: new THAWDBService(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbIntakeQuestions: new GbIntakeQuestions(this.clientId, this.baseUrl, this.envName).getUrls(),
      gbIntake: new GbIntake(this.clientId, this.baseUrl, this.envName).getUrls(),
    };
  }
}
