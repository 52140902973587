import { GbBase } from "./gb-base";

export class GbIntakeQuestions extends GbBase {
  getUrls() {
    return {
      getCaseSpecificQuestions:
        this.getBaseUrl() + "gb-intake-service/intake/v1/questions?" + this.getClientId("auth"),
    };
  }
}
